<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">COUPON DETAIL</h1>
        </div>
        <b-tabs class="mt-3">
          <b-tab title="Set Up Coupons">
            <div class="p-3 bg-white">
              <CouponDetailPanel
                :form="form"
                :id="$route.params.id"
                :couponObj="couponObj"
                :result="result"
              />
            </div>
            <div class="no-gutters bg-white mt-3 py-2 px-3">
              <b-form-checkbox
                switch
                v-model="form.status"
                class="radio-active"
                size="lg"
                :value="1"
                :unchecked-value="0"
              >
                <span class="ml-2 main-label">{{
                  form.status ? "Active" : "Inactive"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-tab>
          <b-tab title="Customer List" v-if="parseInt($route.params.id) !== 0">
            <TabCustomerList
              placeholder="Search Name"
              :filter="filterCustomer"
              :fields="fieldsCustomer"
              :items="itemsCustomer"
              :isBusy="isBusyCustomer"
              :rows="rowsCustomerList"
              @exportExcel="exportExcel"
              :pageOptions="pageOptions"
              :showing="showing"
              :showingTo="showingTo"
              @getPage="getPage"
              @getPageOption="getPageOption"
              @sidebarFilter="sidebarFilter"
              @handleSearch="handleSearch"
            />
          </b-tab>
        </b-tabs>
      </b-form>

      <SidebarFilterCustomerCoupon
        ref="sidebarFilterUsedCoupon"
        :filter="filterCustomer"
        @searchFilter="searchFilter"
      />
    </div>
  </div>
</template>

<script>
import CouponDetailPanel from "@/components/coupon/detail/CouponDetailPanel";
import TabCustomerList from "@/components/coupon/detail/customer/TabCustomerList";
import SidebarFilterCustomerCoupon from "@/components/coupon/detail/customer/SidebarFilterCustomerCoupon";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    CouponDetailPanel,
    TabCustomerList,
    OtherLoading,
    SidebarFilterCustomerCoupon,
  },
  async created() {
    if (this.$route.params.id != 0) {
      await this.getCouponDetail();
      await this.getCustomerUseList();
    } else {
      this.isLoading = false;
    }
  },
  data() {
    return {
      couponObj: {},
      form: {
        name: "",
        allow_other_promotion: false,
        prefix: "",
        max_quantity: 0,
        promotion_value: 0,
        required_point: 0,
        availability: 0,
        activated: 0,
        productSelect: [],
        start_datetime: "",
        end_datetime: "",
        BranchSelect: [],
        status: 0,
        promotion_discount_type_id: 1,
        minimum_amount_requirement: 0,
        user_group_id: 1,
        branch_point: 0,
        promotion_type_id: 4,
        cmg_promotion_value: 0,
        cmg_promotion_discount_type_id: 0,
        image_url: "",
        short_description: "",
        description: "",
        multiply_point: 0,
        point: 0,
      },
      filterCustomer: {
        redeem_date: null,
        used_date: null,
        search: null,
        status: null,
        promotion_id: 0,
        take: 10,
        page: 1,
      },
      fieldsCustomer: [
        {
          label: "Name-Surname",
          key: "name",
        },
        {
          label: "Coupon Code",
          key: "prefix",
        },
        {
          label: "Date/Time Redeemed",
          key: "redeemed_date_string",
        },
        {
          label: "Date/Time Used",
          key: "used_date_string",
        },
        {
          label: "Status",
          key: "status_name",
        },
      ],
      itemsCustomer: [],
      isBusyCustomer: true,
      rowsCustomerList: 0,
      filterExport: {
        redeem_date: null,
        used_date: null,
        search: null,
        status: null,
        promotion_id: 0,
      },
      isLoading: true,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showing: 1,
      showingTo: 10,
      result: 0,
    };
  },
  methods: {
    async getCustomerUseList() {
      this.isBusyCustomer = true;
      this.filterCustomer.promotion_id = parseInt(this.$route.params.id);
      await this.$store.dispatch(
        "getCustomerUsedCouponList",
        this.filterCustomer
      );
      const data = this.$store.state.coupon.customerUserCouponList;

      this.rowsCustomerList = data.total_count;
      if (data.total_count > 0) {
        this.itemsCustomer = data.detail;
      } else {
        this.itemsCustomer = [];
      }

      this.isBusyCustomer = false;
    },
    async exportExcel() {
      this.isLoading = true;
      this.filterExport.redeem_date = this.filterCustomer.redeem_date;
      this.filterExport.used_date = this.filterCustomer.used_date;
      this.filterExport.search = this.filterCustomer.search;
      this.filterExport.status = this.filterCustomer.status;
      this.filterExport.promotion_id = parseInt(this.$route.params.id);
      await this.$store.dispatch("exportCustomerUsedCoupon", this.filterExport);
      const data = this.$store.state.coupon.respExportUsedCoupon;
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Customer_Used_Coupon-` + dateExcel + `.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.isLoading = false;
    },
    getPage(value) {
      this.filterCustomer = value;
      this.getCustomerUseList();
    },
    getPageOption(value) {
      this.filterCustomer = value;
      this.getCustomerUseList();
    },
    sidebarFilter() {
      this.$refs.sidebarFilterUsedCoupon.show();
    },
    searchFilter(value) {
      this.filterCustomer = value;
      this.getCustomerUseList();
    },
    handleSearch(value) {
      this.filterCustomer = value;
      this.getCustomerUseList();
    },
    async getCouponDetail() {
      this.isLoading = true;
      await this.$store.dispatch("getCouponDetail", this.$route.params.id);
      let data = this.$store.state.coupon.couponDetail;
      this.result = data.result;
      this.form = data.detail.detail;
      this.couponObj = data.detail;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
</style>
